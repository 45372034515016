<template>
  <div class="box" :style="{ height: bgHeight }" v-if="show">
    <!-- <div class="header-tip">*绑定后，可接收到该商户的推送消息~</div> -->
    <!-- <div class="img-box">
      <img src="../../assets/bind/bind-logo.png" width="100%" />
    </div> -->
    <div class="content-box">
      <div class="item-box">
        <van-field
          :left-icon="phone"
          v-model="from.phone"
          type="digit"
          maxlength="11"
          placeholder="请输入注册商户手机号"
        />
        <!-- <van-divider :hairline="false" class="divider-style" /> -->
      </div>
      <div class="item-box code">
        <van-field :left-icon="code" v-model.trim="from.code" maxlength="4" placeholder="请输入验证码">
          <template #button>
            <van-button
              @click="getVerifyCode"
              class="code-btn"
              size="small"
              :loading="loadingCode"
              loading-type="spinner"
              type="info"
              >{{ codeBtnText }}</van-button
            >
          </template>
        </van-field>
        <!-- <van-divider :hairline="false" class="divider-style" /> -->
      </div>
    </div>
    <div class="submit-btn" @click="submit">
      <van-loading v-if="loadingBtn" type="spinner" size="20px" class="btn-loading">提交绑定</van-loading>
      <span v-else>提交绑定</span>
    </div>
  </div>
</template>

<script>
import { bindSms, isBind, bind } from "@/api/bind";
import { Toast } from "vant";
export default {
  data() {
    return {
      from: {
        phone: "",
        code: "",
        openId: this.$route.query.openId ? this.$route.query.openId : "",
      },
      // oTUXS5nHydfHndbcqbtmsREqp3E0
      phone: require("../../assets/bind/phone.png"),
      code: require("../../assets/bind/code.png"),
      loadingCode: false,
      codeBtnText: "获取验证码",
      loadingBtn: false,
      codeBtnDisable: true,
      show: false,
      bgHeight: "",
    };
  },
  created() {
    this.bgHeight = document.body.scrollHeight + "px";
    this.checkBind();
  },
  mounted() {},
  methods: {
    checkBind() {
      isBind({ openId: this.from.openId }).then((res) => {
        if (res.bindStatus === 0) {
          this.show = true;
        } else {
          this.$router.replace({
            path: "/unbind",
            query: {
              openId: this.$route.query.openId,
            },
          });
        }
      });
    },
    getVerifyCode() {
      if (this.loadingCode) {
        return;
      }
      if (!this.codeBtnDisable) {
        return;
      }
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

      if (!this.from.phone) {
        Toast({
          message: "手机号码不能为空",
          icon: "cross",
        });
        return false;
      }
      if (!strTemp.test(this.from.phone)) {
        Toast({
          message: "手机号码格式不正确",
          icon: "cross",
          duration: 2 * 1000,
        });
        return false;
      }
      let params = {
        phone: this.from.phone,
      };
      this.sendRequestCode(params);
    },
    // 获取验证码接口
    sendRequestCode(data) {
      this.loadingCode = true;
      bindSms(data)
        .then((res) => {
          this.loadingCode = false;
          Toast({
            message: "发送成功，请注意查收！",
            icon: "success",
          });
          //   this.isCaptcha = res;
          let time = 60,
            i = 0;
          this.codeBtnText = --time + "s";
          let timer = setInterval(() => {
            this.codeBtnText = --time + "s";
            i++;
            if (i == 1) {
              this.codeBtnDisable = false;
            }
          }, 1000);
          setTimeout(() => {
            this.codeBtnText = "获取验证码";
            this.codeBtnDisable = true;
            clearInterval(timer);
          }, time * 1000);
        })
        .catch((err) => {
          this.loadingCode = false;
          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },

    //提交绑定
    submit() {
      if (this.from.phone == "") {
        Toast({
          message: "请输入商户手机号",
          icon: "cross",
        });
        return false;
      }
      if (this.from.code == "") {
        Toast({
          message: "请输入验证码",
          icon: "cross",
        });
        return false;
      }
      this.loadingBtn = true;
      bind(this.from)
        .then((res) => {
          this.loadingBtn = false;
          Toast({
            message: "恭喜你，绑定成功！",
            duration: 2 * 1000,
            icon: "success",
          });
          setTimeout(() => {
            this.$router.push({
              path: "/unbind",
              query: {
                openId: this.$route.query.openId,
              },
            });
          }, 2000);

          console.log(res);
        })
        .catch((err) => {
          this.loadingBtn = false;
          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-button--info {
  background: linear-gradient(270deg, #ff5a5a 0%, #ffb273 100%);
  border: 1px solid #fff3f2;
  color: #ffffff;
  border-radius: 29px;
  width: 182px;
  padding: 10px 16px;
}
/deep/ .van-field__label {
  width: 100px;
}
/deep/ .code .van-field__label {
  line-height: 70px;
}

/deep/ .van-field__left-icon .van-icon {
  font-size: 48px;
}
/deep/ .van-field__left-icon {
  margin-right: 20px;
}

/deep/ .code .van-field__left-icon {
  margin-top: 10px;
}

/deep/ .van-field__control {
  color: #ef3535;
}
.box {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #ffffff;
  background: url("../../assets/bind/bg.png") center center no-repeat;
  background-size: 100% 100%;
}
.header-tip {
  position: absolute;
  height: 80px;
  color: #ef3535;
  font-size: 28px;
  line-height: 80px;
  padding-left: 20px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
}
.img-box {
  width: 100%;
  height: 614px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content-box {
  position: absolute;
  width: 650px;
  height: 432px;
  top: 501px;
  left: 50px;
  background: #fff9f7;
  box-shadow: 0px 4px 8px 1px rgba(255, 179, 179, 0.14);
  border-radius: 20px;
  padding: 79px 45px 79px 40px;
  box-sizing: border-box;
  opacity: 0.8;
  .item-box {
    border-radius: 12px;
    background: #fff;
    opacity: 1;
    padding: 0px 20px;
    .label {
      font-size: 30px;
      color: #333;
      font-weight: bold;
    }
    .codeNum {
      font-size: 32px;
      font-weight: bold;
    }
    .divider-style {
      margin: 0;
    }
    .code-btn {
      font-size: 26px;
      color: #ffffff;
      .van-loading__text {
        color: #ffffff;
        font-size: 26px;
      }
    }
  }
  .code {
    margin-top: 50px;
  }
}
.submit-btn {
  position: absolute;
  top: 1050px;
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  left: 65px;
  right: 65px;
  background: linear-gradient(270deg, #ff5a5a 0%, #ffb273 100%);
  border-radius: 45px;
  z-index: 5;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
}
</style>
